import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderListItemBoxOuter: {
    marginRight: "1rem",
    marginBottom: "1rem",
    borderRadius: "0.75rem",
    height: "7rem",

    backgroundColor: theme.palette.common.black,
  },

  orderListItemBoxOuterOne: {
    height: "14rem",
  },

  orderListItemBoxOuterLarge: {
    height: "10.5rem",
  },

  orderListItemBox: {
    position: "relative",
    borderRadius: "0.75rem",
    paddingTop: "0.25rem",
    height: "100%",
    textAlign: "-webkit-center",
    alignItems: "center",
    backgroundColor: theme.palette.common.blackThree,
  },

  orderListItemBoxAlarm: {
    animationName: `$myEffect`,
    animationIterationCount: "infinite",
    animationDuration: "1.5s",
    animationTimingFunction: `${theme.transitions.easing.easeInOut}`,
  },

  "@keyframes myEffect": {
    "0%": {
      backgroundColor: `${theme.palette.common.blackThree}`,
    },
    "50%": {
      backgroundColor: `${theme.palette.common.orangeRed}`,
    },
    "100%": {
      backgroundColor: `${theme.palette.common.blackThree}`,
    },
  },

  dineInMainBox: {
    backgroundColor: `${theme.palette.common.redSelected}30 !important`,
  },

  orderNoText: {
    fontSize: "3rem",
    lineHeight: "3rem",
    marginBottom: "1.6rem",
    fontWeight: 400,
  },

  orderNoTextOne: {
    fontSize: "8rem",
    lineHeight: "8rem",
  },

  orderNoTextLarge: {
    fontSize: "4.5rem",
    lineHeight: "4.5rem",
  },

  textLabel: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: theme.palette.common.white,
  },

  footer: {
    position: "absolute",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    bottom: 0,
    left: 0,
    width: "100%",
  },

  statusBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    backgroundColor: `${theme.palette.common.blackTen}30`,
  },

  textBox: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))
const maxLargeCount = 21

const ReadyOrderListItem = ({ orderItem, totalCount, ind }) => {
  const [counter, setCounter] = React.useState(1)
  const classes = useStyles()

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1)
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [counter])

  let maxXtraLargeCount = totalCount > 10 ? (totalCount > 16 ? 2 : 5) : 10

  return (
    <Grid
      item
      xs={ind <= maxXtraLargeCount ? 12 : totalCount <= maxLargeCount ? 6 : 4}
    >
      <Box
        classes={{
          root: clsx(classes.orderListItemBoxOuter, {
            [classes.orderListItemBoxOuterOne]: ind <= maxXtraLargeCount,
            [classes.orderListItemBoxOuterLarge]:
              ind > maxXtraLargeCount && totalCount <= maxLargeCount,
          }),
        }}
      >
        <Box
          classes={{
            root: clsx(classes.orderListItemBox, {
              [classes.orderListItemBoxAlarm]: counter > 0,
            }),
          }}
        >
          <Box
            classes={{
              root: classes.textBox,
            }}
          >
            <Typography
              variant="h5"
              classes={{
                root: clsx(classes.orderNoText, {
                  [classes.orderNoTextOne]: ind <= maxXtraLargeCount,
                  [classes.orderNoTextLarge]:
                    ind > maxXtraLargeCount && totalCount <= maxLargeCount,
                }),
              }}
            >
              {`#${
                orderItem.orderType === "inStore"
                  ? orderItem.orderNumber
                  : orderItem.orderType === "takeAway"
                  ? orderItem.id
                  : ""
              }`}
            </Typography>
          </Box>

          <Box
            classes={{
              root: classes.footer,
            }}
          >
            <Box
              classes={{
                root: clsx(classes.statusBox, {
                  [classes.dineInMainBox]: orderItem.orderType === "inStore",
                }),
              }}
            >
              {orderItem.orderType === "inStore" ? (
                <>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.textLabel }}
                  >
                    {"IN-STORE"}
                  </Typography>
                </>
              ) : orderItem.orderType === "takeAway" ? (
                <>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.textLabel }}
                  >
                    {"ONLINE"}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default ReadyOrderListItem
