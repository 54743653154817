import * as React from "react"
import axios from "axios"

import {
  setOrdTimesNAlarm,
  addSingleOrder,
  isSelectedOrdValid,
  setLocalOrders,
} from "../helperFunc/mainImportFilter"
import TvInCont from "./tvInCont"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import { socket } from "../../sockets/web-sockets"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const TvPanel = ({
  user,
  kitchenSettings,
  dispatchFeedback,
  setAlarm,
  alarm,
}) => {
  const [ordersIn, setOrdersIn] = React.useState([])
  const [localOrdersIn, setLocalOrdersIn] = React.useState([])
  const [selectedOrder, setSelectedOrder] = React.useState({})
  const [counter, setCounter] = React.useState(0)
  const [loading, setLoading] = React.useState(true)

  const classes = useStyles()

  const ordersInRef = React.useRef([])
  const selectedOrderRef = React.useRef({})
  const localOrdersInRef = React.useRef([])
  const timerRef = React.useRef(null)

  ordersInRef.current = ordersIn
  selectedOrderRef.current = selectedOrder
  localOrdersInRef.current = localOrdersIn

  const timeToRefresh = 6.5 * 60000 // (6.5) * 60000

  const handleAddSingleOrder = React.useCallback(
    data => {
      //console.log("handleAddSingleOrder")
      const res = addSingleOrder(ordersInRef.current, data, kitchenSettings)
      if (res) {
        setOrdersIn(res.orderRes)
        if (Object.keys(selectedOrderRef.current).length > 0) {
          let isSelectOrdValidInd = isSelectedOrdValid(
            res.orderRes,
            selectedOrderRef.current
          )
          if (isSelectOrdValidInd < 0) {
            setSelectedOrder({})
          }
        }
        if (res.tvAlarm !== "") {
          setAlarm(res.tvAlarm)
        }
      }
    },
    [kitchenSettings, setAlarm]
  )

  const getFirstOrders = React.useCallback(
    isSetLoading => {
      axios
        .get(
          process.env.GATSBY_STRAPI_URL +
            "/api/kitchen-setting/first-get-all-orders",
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          // SortOrders

          const localOrdersToFilter = [...response.data.localOrders]
          const ordersToFilter = [
            ...response.data.doneOrders,
            ...response.data.workingOrders,
          ]
          const { orderRes } = setOrdTimesNAlarm(
            ordersToFilter,
            kitchenSettings
          )
          const { localOrdersRes } = setLocalOrders(localOrdersToFilter)
          setOrdersIn(orderRes)
          setLocalOrdersIn(localOrdersRes)

          if (isSetLoading) {
            setLoading(false)
          }
        })
        .catch(error => {
          console.error(error)
          if (isSetLoading) {
            setLoading(false)
          }
          handleError(error, dispatchFeedback)
        })
    },
    [dispatchFeedback, user.jwt, kitchenSettings]
  )

  const handleNewLocalOrder = React.useCallback(
    data => {
      //console.log("handleNewLocalOrder")
      const existingOrdInd = localOrdersInRef.current.findIndex(
        element => element.orderNumber === parseInt(data.orderNumber)
      )

      if (existingOrdInd < 0) {
        let localOrdersInCopy = [...localOrdersInRef.current]
        localOrdersInCopy.push(data)
        const { localOrdersRes } = setLocalOrders(localOrdersInCopy)
        setLocalOrdersIn(localOrdersRes)
        setAlarm(`IN-STORE ORDER #${data.orderNumber}`)
      }
    },
    [setAlarm]
  )

  const handleRemoveLocalOrder = React.useCallback(data => {
    //console.log("handleRemoveLocalOrder")
    const existingOrdInd = localOrdersInRef.current.findIndex(
      element => element.orderNumber === parseInt(data)
    )

    if (existingOrdInd > -1) {
      let localOrdersInCopy = [...localOrdersInRef.current]
      localOrdersInCopy.splice(existingOrdInd, 1)
      const { localOrdersRes } = setLocalOrders(localOrdersInCopy)
      setLocalOrdersIn(localOrdersRes)
    }
  }, [])

  React.useEffect(() => {
    getFirstOrders(true)
  }, [getFirstOrders])

  React.useEffect(() => {
    if (socket) {
      socket.on("newOrderReceived", data => {
        handleAddSingleOrder(data)
      })
      socket.on("orderUpdated", data => {
        handleAddSingleOrder(data)
      })
    }
  }, [handleAddSingleOrder])

  React.useEffect(() => {
    if (socket) {
      socket.on("newLocalOrder", data => {
        handleNewLocalOrder(data)
      })
      socket.on("removeLocalOrder", data => {
        handleRemoveLocalOrder(data)
      })
    }
  }, [handleNewLocalOrder, handleRemoveLocalOrder])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1)
      } else {
        setAlarm("")
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [counter, setAlarm])

  React.useEffect(() => {
    if (alarm !== "") {
      setCounter(10)
    }
  }, [alarm])

  //console.log("Main Rend")

  React.useEffect(() => {
    if (socket) {
      socket.on("connect_error", () => {
        if (typeof window !== "undefined") {
          timerRef.current = setTimeout(() => {
            window.location.reload()
          }, 60000)
        }
      })
    }
    return () => clearInterval(timerRef.current)
  }, [])

  React.useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("ReConnected")
        getFirstOrders(false)
      })
    }
  }, [getFirstOrders])

  React.useEffect(() => {
    if (socket) {
      socket.on("refreshAllDisplays", data => {
        if (data) {
          if (typeof window !== "undefined") {
            window.location.reload()
          }
        }
      })
    }
  }, [])

  React.useEffect(() => {
    const interval = setInterval(() => {
      getFirstOrders(false)
    }, timeToRefresh)

    return () => clearInterval(interval)
  }, [getFirstOrders, timeToRefresh])

  return (
    <>
      {loading ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      ) : (
        <TvInCont
          ordersIn={ordersIn}
          localOrdersIn={localOrdersIn}
          kitchenSettings={kitchenSettings}
        />
      )}
    </>
  )
}

export default TvPanel
